import { getCSRFToken } from '../../utils';

(() => {
  const articleComponentClass = 'article__main';
  const serviceUrl = '/content/aa-ous/services/articles.read.json';
  const pathAttr = 'data-path';

  // marks an article as 'read'
  const markArticleAsRead = async path => {
    if (path) {

      // if exists, remove .html extension from the path
      path = path.replaceAll('.html', '');
      
      const origin = window.location.origin;
      const queryParams = new URLSearchParams({
        articlePath: path,
      });

      return fetch(`${origin}${serviceUrl}?${queryParams}`, {
        method: 'POST',
        headers: {
          'CSRF-Token': await getCSRFToken(),
        },
      })
        .then(resp => {
          return resp;
        })
        .catch(error => {
          console.warn(error);
          return error;
        });
    }

    return Promise.reject();
  };

  const initArticle = $article => {
    let dataPath;

    const initVariables = () => {
      dataPath = $article?.getAttribute(pathAttr);
    };

    initVariables();

    // as soon as the page is loaded, calling the service URL to mark the article as read
    // in future, if there is any condition that needs to be set, for when the article must be marked as read,
    // add that condition here, or inside the markArticleAsRead fn
    markArticleAsRead(dataPath);
  };

  const init = () => {
    const $articleComponents = document.querySelectorAll(
      `.${articleComponentClass}`
    ) as NodeListOf<HTMLElement>;

    $articleComponents.forEach($article => {
      initArticle($article);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
