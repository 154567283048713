const getArticleHTML = (
  article,
  readMoreText
) => `<div class="u-body-font--eyebrow emu-spacing-mb__xs">
						${article.eyebrowTitle}
					</div>
					<div class="article__image emu-spacing-mb__xs">
						<img src="${article.assetThumbnail}" alt="${article.assetThumbnailAltText}" />
					</div>
					<div class="emu-spacing-mb__xs">${article.title}</div>
					<div class="article__cta">
						<div class="v2 button">
							<a href="${article.path}" class="emu-button emu-button-v2 btn--as-link">
								<span data-ref="button-text" class="cmp-button__text">${readMoreText}</span>
							</a>
						</div>
					</div>`;

export default getArticleHTML;
