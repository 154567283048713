(() => {
  // when carousel has less content width than its container width, adding a class to hide arrows, dots and to center the content horizontally
  const adjustCarouselDimensions = () => {
    const carouseInstances = Object.values(window._tnsInstances || {});
    if (carouseInstances?.length) {
      let winWidth = window.innerWidth;

      // once the window load event happened, setting the carousel height
      window.addEventListener('load', () => {
        carouseInstances.forEach(inst => {
          // making sure that the carousel height is updated when every image is loaded
          setTimeout(() => {
            // @ts-ignore
            inst?.updateSliderHeight?.();
          }, 2000);
        });
      });

      const toggleCarouselDisabledClass = () => {
        carouseInstances.forEach(inst => {
          // @ts-ignore
          const info = inst?.getInfo?.();
          if (info && info.container) {
            const carouselWidth = info.container.clientWidth;
            const mainCarousel = info.container.closest('.aaaem-carousel');

            // @ts-ignore
            inst?.updateSliderHeight?.();

            mainCarousel?.classList?.toggle(
              'u-carousel--disabled',
              carouselWidth <= mainCarousel.clientWidth
            );
          }
        });
      };
      toggleCarouselDisabledClass();

      // if the carousel's content width is
      window.addEventListener('resize', () => {
        const curWidth = window.innerWidth;
        if (curWidth !== winWidth) {
          winWidth = curWidth;
          toggleCarouselDisabledClass();
        }
      });
    }
  };

  // updates the TNS Live Region Text to include text in 1/8(current/total) format
  const addTnsLiveRegionText = () => {
    const carouseInstances = Object.values(window._tnsInstances || {});
    if (carouseInstances?.length) {
      carouseInstances.forEach(inst => {
        // @ts-ignore
        const curInfo = inst?.getInfo?.();

        const $liveRegion = curInfo?.container
          ?.closest?.('.carousel')
          ?.querySelector?.('.tns-liveregion') as HTMLElement;

        const updateLiveRegion = info => {
          try {
            const curIndex = info.navCurrentIndex + 1;
            const total = info.slideCount;
            $liveRegion.innerHTML = curIndex + '/' + total;
            $liveRegion.classList.add('can-show');
          } catch (e) {
            console.warn(e);
            $liveRegion.classList.remove('can-show');
          }
        };

        if ($liveRegion) {
          // @ts-ignore
          inst?.events?.on?.('transitionEnd', info => {
            updateLiveRegion(info);
          });

          updateLiveRegion(curInfo);
        }
      });
    }
  };

  const init = () => {
    adjustCarouselDimensions();
    addTnsLiveRegionText();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
