(() => {
  // handles the faq component on the page
  const handleFaq = ($faq: HTMLElement) => {
    let $tabTriggers,
      selectDropdownId,
      containerId,
      $form,
      $formCta,
      $searchInput,
      searchEndPoint,
      $loader,
      searchTerm,
      results,
      $searchResultsTitleTerm,
      $noResultsTitleTerm,
      $searchResultsStage,
      searchInputId,
      $backToSupportCta;

    // when tab is changed, re-calculate the accordion heights and open the first accordion tile
    const tabChanged = e => {
      const activeTab = e?.detail?.content;
      if (containerId && activeTab && activeTab.closest(`#${containerId}`)) {
        collapseAllInactiveAccordions();
        const activeAccordion = activeTab.querySelector('.aaaem-accordion');
        const activeAccordionId = activeAccordion?.getAttribute('id');
        if (activeAccordionId) {
          const accInst = window._badgerInstances?.[activeAccordionId];
          accInst?.init();
          const timer = setTimeout(() => {
            const firstAccHeader = accInst?.headers?.[0];
            const firstBadgerOpen =
              firstAccHeader?.classList?.contains('-ba-is-active');
            if (!firstBadgerOpen && firstAccHeader) {
              accInst?.open(0);
            }
            clearTimeout(timer);
          }, 200);
        }
      }
    };

    // By default every accordion shows the first one expanded. But, coz the accordion is present inside tabs for FAQ, the height is not calculated well
    // Also the state of the accordion is being saved when tabs is switched. Ex: Tab 1 shows previously expanded accordions, when switched back from another tab and the requirement shows that only first accordion must be opened when switching tabs
    // This function hides all the opened accordions inside the inactive tabs.
    // The heights will be calculated when tab is switched. Also the expansion takes place after the tab is switched
    const collapseAllInactiveAccordions = () => {
      const inactiveTabs = $faq?.querySelectorAll(
        '.aaaem-tabs__tabpanel[hidden="hidden"]'
      ) as NodeListOf<HTMLElement>;
      if (inactiveTabs?.length) {
        Array.from(inactiveTabs).forEach(tab => {
          const acc = tab?.querySelector('.aaaem-accordion');
          const accId = acc?.getAttribute('id');
          if (accId) {
            const accInst = window._badgerInstances?.[accId];
            accInst?.closeAll?.();
          }
        });
      }
    };

    // when select dropdown is changed, a corresponding tab will be activated
    // on page load when a select dropdown has a default select, makes the corresponding tab active
    // if none of the select options is selected by default, sets the first one as default
    const handleSelectTab = () => {
      $tabTriggers = $faq.querySelectorAll(
        '.aaaem-tabs__tab a'
      ) as NodeListOf<HTMLAnchorElement>;

      // making sure that the tab is selected for the selected option in the select dropdown
      let $selectedOption = ($faq?.querySelector(
        `.emu-dropdown-menu__group [aria-selected="true"]`
      ) ||
        $faq?.querySelector(
          '.emu-dropdown-menu__group .dropdown-menu-item'
        )) as HTMLButtonElement;
      $selectedOption?.click?.();

      if (selectDropdownId && $tabTriggers?.length) {
        // when the select dropdown is changed, selecting the corresponding tab
        window.Bus.on('emu-form-select:change', ({ id, selectOptions }) => {
          if (id === selectDropdownId) {
            const selectedVal = selectOptions.find(e => e.selected)?.value;
            $tabTriggers[selectedVal - 1]?.click?.();
          }
        });
      }
    };

    // hides/shows the loader
    const toggleLoader = (state: Boolean) => {
      $loader?.classList.toggle('loader--active', state);
    };

    // updates the search term in search results title and search no results title
    const updateSearchTermInText = () => {
      if ($searchResultsTitleTerm) {
        $searchResultsTitleTerm.innerText = searchTerm;
      }

      if ($noResultsTitleTerm) {
        $noResultsTitleTerm.innerText = searchTerm;
      }
    };

    // for the search results appends the badger.
    const appendBadger = () => {
      const accElem = $searchResultsStage?.querySelector('.aaaem-accordion');

      if (accElem && window.AAAEM.plugins.badgerAccordion) {
        try {
          const badgerInst = new window.AAAEM.plugins.badgerAccordion(accElem);
          if (window._badgerInstances) {
            window._badgerInstances[`faq-search-${searchInputId}`] = badgerInst;
          }

          // timer for the effect to show accordion opening
          setTimeout(() => {
            badgerInst.open(0);
          }, 200);
        } catch (e) {
          console.warn(e, 'accordion failed to load');
        }
      }
    };

    // toggles the search results view and the regular view based on the results
    const toggleSearchView = (showSearchResults: Boolean) => {
      if (showSearchResults) {
        if (results) {
          $faq.classList.remove('faq__container--show-no-results');
        } else {
          $faq.classList.add('faq__container--show-no-results');
        }

        $faq.classList.add('faq__container--show-results-section');
      } else {
        $faq.classList.remove(
          'faq__container--show-results-section',
          'faq__container--show-no-results'
        );
      }
    };

    // appends results to the search Stage
    const appendResults = () => {
      $searchResultsStage.innerHTML = '';

      if (results) {
        $searchResultsStage.append(results);
      }
    };

    // makes a call to the search end point and populates data to the search
    const searchInFaq = e => {
      e.preventDefault();
      if (searchEndPoint && $searchInput) {
        toggleLoader(true);
        searchTerm = ($searchInput.value || '')?.trim();
        const pattern = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (searchTerm) {
          updateSearchTermInText();
          if (pattern.test(searchTerm)) {
            toggleSearchView(true);
            toggleLoader(false);
          } else {
            fetch(
              `${searchEndPoint}?searchItem=${encodeURIComponent(searchTerm)}`
            )
              .then(function (response) {
                // When the page is loaded convert it to text
                return response.text();
              })
              .then(function (html) {
                var parser = new DOMParser();
                let data = parser.parseFromString(html, 'text/html');
                results = data?.querySelector('.accordion dt')
                  ? data?.querySelector('.accordion')
                  : null;
              })
              .catch(err => {
                console.warn('Failed to fetch page: ', err);
                results = null;
              })
              .finally(() => {
                appendResults();
                toggleSearchView(true);
                appendBadger();
                toggleLoader(false);
              });
          }
        } else {
          toggleLoader(false);
        }
      }
    };

    // initiates the variables used across the file
    const initVariables = () => {
      $tabTriggers = $faq.querySelectorAll(
        '.aaaem-tabs__tab a'
      ) as NodeListOf<HTMLAnchorElement>;
      selectDropdownId = $faq.querySelector('.emu-form-dropdown')?.id;
      containerId = $faq.id;
      $form = $faq.querySelector('.faq__search-form');
      $formCta = $faq.querySelector('.faq__search-cta');
      $loader = $faq.querySelector('.faq__loader');
      $searchInput = $faq.querySelector('.faq__search-field input');
      searchEndPoint = $faq
        .querySelector('.faq__tabs-inner-container')
        ?.getAttribute('data-search-path');
      $searchResultsTitleTerm = $faq.querySelector(
        '.faq__search-results-title i'
      );
      $noResultsTitleTerm = $faq.querySelector('.faq__search-results-text i');
      $searchResultsStage = $faq.querySelector('.faq__search-results-stage');
      searchInputId = $searchInput?.id;
      $backToSupportCta = $faq.querySelector(
        '.faq__back-to-support'
      ) as HTMLButtonElement;
    };

    // appends the events that are needed
    const appendEvents = () => {
      window.addEventListener('tabby', e => {
        tabChanged(e);
      });

      window.Bus.on('aaous-text-field-with-close:reset', ({ id }) => {
        if (id === searchInputId) {
          toggleSearchView(false);
        }
      });

      $form?.addEventListener('submit', searchInFaq);
      $formCta?.addEventListener('click', searchInFaq);
      $backToSupportCta.addEventListener('click', e => {
        e.preventDefault();
        toggleSearchView(false);
        if ($searchInput) {
          $searchInput.value = '';
        }
      });
    };

    const init = () => {
      initVariables();
      appendEvents();
      collapseAllInactiveAccordions();
      handleSelectTab();
    };

    init();
  };

  const init = () => {
    const $faqs = document.querySelectorAll(
      '.faq__container'
    ) as NodeListOf<HTMLElement>;
    if ($faqs?.length) {
      $faqs.forEach($faq => {
        handleFaq($faq);
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
