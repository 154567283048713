import { getCSRFToken } from '../../utils';

(() => {
  const initSurvey = ($trigger: HTMLAnchorElement) => {
    let modalId,
      $modal,
      $form,
      formId,
      closeParamKey: string,
      closeParamVal: string,
      personaParam: string,
      defaultPersona: string,
      versionParamKey: string,
      versionParamVal: string,
      surveyRecurrenceParamKey: string,
      surveyRecurrenceParamVal: string,
      formSubmissionTriggered = false,
      endpoint: string,
      surveyTimeInterval: string,
      canShowModal: boolean;

    // submits the form. Form Close boolean must be passed when the close button is clicked
    const submitSurvey = async (data, fromClose?) => {
      if (
        endpoint &&
        personaParam &&
        closeParamKey &&
        versionParamKey &&
        surveyRecurrenceParamKey
      ) {
        formSubmissionTriggered = true;
        const queryParams = new URLSearchParams(data?.fullFormData || {});

        let personaVal: string;
        try {
          if (fromClose) {
            queryParams.set(closeParamKey, closeParamVal || 'true');
            personaVal = defaultPersona;
          } else {
            const personaKey =
              Object.keys(data.currentStepData).find(key =>
                key.includes(personaParam)
              ) || '';
            personaVal = data.currentStepData[personaKey]?.[0];
            queryParams.set(personaParam, defaultPersona);
          }
        } catch (e) {
          personaVal = '';
        }

        if (personaVal) {
          queryParams.set(personaParam, personaVal);
          queryParams.set(versionParamKey, versionParamVal);
          queryParams.set(surveyRecurrenceParamKey, surveyRecurrenceParamVal);
          window.Bus.emit('aaous-step-form:show-loader', { id: formId });

          fetch(`${endpoint}?${queryParams}`, {
            method: 'POST',
            headers: {
              'CSRF-Token': await getCSRFToken(),
            },
          })
            .then(
              resp => {
                if (resp.ok) {
                  window.Bus.emit('aaous-step-form:show-success-msg', {
                    id: formId,
                  });
                  $modal.classList.add('survey__modal--success');
                } else {
                  window.Bus.emit('aaous-step-form:show-error-msg', {
                    id: formId,
                  });
                }
              },
              () => {
                window.Bus.emit('aaous-step-form:show-error-msg', {
                  id: formId,
                });
              }
            )
            .catch(e => {
              console.warn(e);
              window.Bus.emit('aaous-step-form:show-error-msg', { id: formId });
            })
            .finally(() => {
              window.Bus.emit('aaous-step-form:hide-loader', { id: formId });
            });
        } else {
          window.Bus.emit('aaous-step-form:show-error-msg', {
            id: formId,
          });
        }
      }
    };

    // initialized the variables used across the file
    const initVariables = () => {
      modalId = $trigger.getAttribute('data-modal');
      $modal = document.querySelector(
        `[data-component="modal"][data-id="${modalId}"]`
      );
      $form = $modal?.querySelector('.step-form') as HTMLFormElement;
      formId = $form?.id;
      personaParam = $trigger?.getAttribute('data-persona-param-key') || '';
      defaultPersona =
        $trigger?.getAttribute('data-default-persona-value') || '';
      closeParamKey =
        $trigger?.getAttribute('data-close-click-param-key') || '';
      closeParamVal = $trigger?.getAttribute('data-close-click-value') || '';
      versionParamKey = $trigger?.getAttribute('data-version-param-key') || '';
      versionParamVal =
        $trigger?.getAttribute('data-version-param-value') || '';
      surveyRecurrenceParamKey =
        $trigger?.getAttribute('data-survey-recurrence-param-key') || '';
      surveyRecurrenceParamVal =
        $trigger?.getAttribute('data-survey-recurrence-param-value') || '';
      surveyTimeInterval =
        $trigger?.getAttribute('data-survey-time-interval') || '';
      endpoint = $form?.getAttribute('action')
        ? window.location.origin + $form?.getAttribute('action')
        : '';
    };

    // appending the events
    const appendEvents = () => {
      if (formId) {
        // when submit button is clicked, submit the form
        window.Bus?.on('aaous-step-form:submit-clicked', data => {
          if (data.id === formId) {
            submitSurvey(data);
          }
        });
      }

      if (modalId) {
        // when modal is clicked, and the form is not submitted yet, submit the form with close parameters
        window.Bus?.on('emu-modal:close', ({ id }) => {
          if (id === modalId && formSubmissionTriggered === false) {
            submitSurvey({}, true);
          }
        });
      }
    };

    // show the modal
    const showModal = () => {
      $trigger?.click?.();
      localStorage.setItem('surveyModalTimestamp', Date.now().toString());
    };

    // checks if the modal can be shown based on the time interval
    const checkModalTimestamp = () => {
      const currentTime = Date.now();
      const storedTimestamp = localStorage.getItem('surveyModalTimestamp');

      // show the modal if no timestamp exists
      if (!storedTimestamp) {
        return true;
      }

      const timeDifferenceInDays =
        (currentTime - Number(storedTimestamp)) / (1000 * 60 * 60 * 24);

      if (timeDifferenceInDays > Number(surveyTimeInterval)) {
        return true;
      }

      return false;
    };

    if ($trigger instanceof HTMLAnchorElement) {
      initVariables();
      appendEvents();
      canShowModal = checkModalTimestamp();

      if (canShowModal) {
        showModal();
      }
    }
  };

  const init = () => {
    const $surveyTriggers = document.querySelectorAll(
      '.survey__trigger'
    ) as NodeListOf<HTMLAnchorElement>;

    $surveyTriggers.forEach($surveyTrigger => {
      initSurvey($surveyTrigger);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
