(() => {
  // for teaser with just image and no content, padding is being added for the empty div
  // to avoid that, detecting the content and adding a class, so that the space can be removed
  const isTeaserTextEmpty = teaser => {
    if (teaser) {
      const teaserContent = teaser.querySelector('.content')?.innerText || '';
      if (teaserContent?.trim()?.length === 0) {
        teaser
          .querySelector('.cmp-teaser')
          .classList.add('u-teaser--no-content');
      }
    }
  };

  const handleTeaser = teaser => {
    isTeaserTextEmpty(teaser);
  };

  const init = () => {
    const teasers = document.querySelectorAll('.teaser');

    if (teasers.length) {
      teasers.forEach(teaser => {
        handleTeaser(teaser);
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
